// PACKAGES
import React from 'react';

/**
 * @name Service
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Service for mezmerikgroup.com
 */
export default function Service ({ children, icon, title }) {
    return (
        <div className="service">
            <div className="service__icon-container">
                <img src={icon} alt={title} className="service__icon" />
            </div>
            <div className="service__content">
                <h3 className="service__title">{title}</h3>
                <p className="service__copy">{children}</p>
            </div>
        </div>
    );
}
