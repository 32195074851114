// PACKAGES
import React, { Component } from 'react';

/**
 * @name ScrollIcon
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description ScrollIcon inserts a scroll to top icon that is positions in the bottom right corner
 */
export default class ScrollIcon extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    constructor (props) {
        super(props);

        this.scrollIcon = React.createRef();
        this.intervalId = 0;
    }

    componentDidMount () {
        document.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount () {
        document.removeEventListener('scroll', this.handleScroll, false);
    }

    /**************************************************************
     * EVENT HANDLING
     *************************************************************/
    handleScroll = () => {
        const scrollIcon = this.scrollIcon.current;

        window.scrollY > 50
            ? scrollIcon.classList.add('scroll-icon--visible')
            : scrollIcon.classList.remove('scroll-icon--visible');
    }

    /**************************************************************
     * METHODS
     *************************************************************/
    scrollToTop = () => {
        this.intervalId = setInterval(() => {
            if (window.pageYOffset === 0) {
                clearInterval(this.intervalId);
            }
            window.scroll(0, window.pageYOffset - 50);
        }, 16.66);
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        return (
            <div ref={this.scrollIcon} className="scroll-icon" onClick={this.scrollToTop}>
                <i className="scroll-icon__icon"></i>
            </div>
        );
    }
}
