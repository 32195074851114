// PACAKGES
import React, { Component } from 'react';
// COMPONENTS
import Form from './Form';
import TextArea from './TextArea';
import TextInput from './TextInput';

/**
 * @name ContactForm
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description ContactForm for mezmerikgroup.com
 */
export default class ContactForm extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    state = {
        loading: false,
        success: false,
    }

    /**************************************************************
     * EVENT HANDLING
     *************************************************************/
    handleSubmit = (formState) => {
        this.setState({ loading: true });

        fetch('/email/contact', {
            method: 'POST',
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(formState.values)
        })
            .then((res) => res.json())
            .then((res) => this.setState({ loading: false, success: true, message: res.message }))
            .catch(() => this.setState({ loading: false }));
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        return (
            <React.Fragment>
                {this.state.success ? (
                    <div>
                        Thank you for reaching out. We'll be in touch soon.
                    </div>
                ) : (
                    <Form onSubmit={(formState) => this.handleSubmit(formState)}>
                        <TextInput
                            name="name"
                            placeholder="Name"
                            validation={['required']}
                        />
                        <TextInput
                            name="email"
                            placeholder="Email"
                            validation={['email', 'required']}
                        />
                        <TextInput
                            name="subject"
                            placeholder="Subject"
                            validation={['required']}
                        />
                        <TextArea
                            name="message"
                            placeholder="Message"
                            validation={['required']}
                            rows="6"
                        />
                    </Form>
                )}
            </React.Fragment>
        );
    }
}
