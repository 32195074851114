// PACKAGES
import React, { Component } from 'react';
// COMPONENTS
import Slide from './Slide';
// STATIC
import iconChevronLeft from '../static/icon-chevron-left.png';
import iconChevronRight from '../static/icon-chevron-right.png';

/**
 * @name Slider
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Slider for mezmerikgroup.com
 */
export default class Slider extends Component {
    state = {
        slide: 1,
        totalSlides: React.Children.count(this.props.children)
    }

    handlePrevClick = () => {
        const slide = this.state.slide === 1
            ? this.state.totalSlides
            : this.state.slide - 1;

        this.setState({ slide });
    }

    handleNextClick = () => {
        const slide = this.state.slide !== this.state.totalSlides
            ? this.state.slide + 1
            : 1;

        this.setState({ slide });
    }

    handleTimelineClick = (slide) => this.setState({
        slide,
    })

    render () {
        return (
            <div className="slider">
                <ul className="slider__timeline">
                    {React.Children.map(this.props.children, (child, index) => {
                        const slide = index + 1;
                        const active = this.state.slide === slide;
                        const title = child.props.title || '';

                        const itemClassList = active
                            ? 'slider__timeline-item slider__timeline-item--active'
                            : 'slider__timeline-item';

                        const circleClassList = active
                            ? 'slider__timeline-circle slider__timeline-circle--active'
                            : 'slider__timeline-circle';

                        const numberClassList = active
                            ? 'slider__timeline-number slider__timeline-number--active'
                            : 'slider__timeline-number';

                        const titleClassList = active
                            ? 'slider__timeline-title slider__timeline-title--active'
                            : 'slider__timeline-title';

                        return (
                            <div key={slide} className="slider__timeline-container" onClick={this.handleTimelineClick.bind(null, slide)}>
                                <li className={itemClassList}>
                                    <div className={circleClassList}>
                                        <span className={numberClassList}>{slide}</span>
                                    </div>
                                    <span className="slider__timeline-line"></span>
                                </li>
                                <h3 className={titleClassList}>{title}</h3>
                            </div>
                        );
                    })}
                </ul>
                <img
                    className="slider__previous"
                    src={iconChevronLeft}
                    alt="Previous Slide Arrow"
                    onClick={this.handlePrevClick}
                />
                <img
                    className="slider__next"
                    src={iconChevronRight}
                    alt="Next Slide Arrow"
                    onClick={this.handleNextClick}
                />
                <ul className="slider__view">
                    {React.Children.map(this.props.children, (child, index) => {
                        const position = index + 1;
                        return (
                            <Slide visible={this.state.slide === position}>
                                {child}
                            </Slide>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
