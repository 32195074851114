// PACKAGES
import React from 'react';

/**
 * @name Main
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Main for mezmerikgroup.com
 */
export default function Main ({ children }) {
    const classList = typeof window !== 'undefined' && window.location.pathname === '/'
        ? 'main main--home'
        : 'main';

    return (
        <main className={classList}>
            {children}
        </main>
    );
}
