// PACKAGES
import React from 'react';

/**
 * @name Section
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Section for mezmerikgroup.com
 */
export default function Section ({ black, children, className, id }) {
    let classList = black ? 'section section--black' : 'section';
    classList = className ? `${classList} ${className}` : classList;

    return (
        <section className={classList} id={id}>
            {children}
        </section>
    );
}
Section.defaultProps = {
    black: false,
};
