// PACKAGES
import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * @name Navigation
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Navigation for mezmerikgroup.com
 */
export default function Navigation () {
    return (
        <nav className="navigation">
            <div className="navigation__link-container">
                <NavLink
                    activeClassName="navigation__link--active"
                    className="navigation__link"
                    exact
                    to="/#"
                >
                    Home
                </NavLink>
            </div>
            <div className="navigation__link-container">
                <NavLink
                    activeClassName="navigation__link--active"
                    className="navigation__link"
                    to="/#services"
                >
                    Services
                </NavLink>
            </div>
            <div className="navigation__link-container">
                <NavLink
                    activeClassName="navigation__link--active"
                    className="navigation__link"
                    to="/#process"
                >
                    Process
                </NavLink>
            </div>
            <div className="navigation__link-container">
                <NavLink
                    activeClassName="navigation__link--active"
                    className="navigation__link"
                    to="/#technology"
                >
                    Technology
                </NavLink>
            </div>
            <div className="navigation__link-container">
                <NavLink
                    activeClassName="navigation__link--active"
                    className="navigation__link"
                    to="/#contact"
                >
                    Contact
                </NavLink>
            </div>
        </nav>
    );
}
