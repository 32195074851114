// PACKAGES
import React from 'react';

/**
 * @name Menu
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Menu for mezmerikgroup.com
 */
export default function Menu (props) {
    const classList = props.active ? 'menu menu--spin is-active' : 'menu menu--spin';

    return (
        <button className={classList} type="button" onClick={props.onClick}>
            <span className="menu-box">
                <span className="menu-inner"></span>
            </span>
        </button>
    );
}
