// PACKAGES
import React from 'react';

/**
 * @name Button
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Button for mezmerikgroup.com
 */
export default function Button ({ category, children, className }) {
    let classList = category ? `button button--${category}` : 'button';
    classList = className ? `${classList} ${className}` : classList;

    return (
        <button className={classList} data-text={children}>
            {children}
        </button>
    );
}
