// PACKAGES
import React from 'react';

export function Discovery () {
    return (
        <div>
            <h3>Discovery</h3>
            <p>
                We start out by discussing your project in depth. When we understand your
                business, and users, we create personas for each user type and begin developing
                application requirements. We then draft user stories to set our process in motion.
            </p>
        </div>
    );
}

export function Design () {
    return (
        <div>
            <h3>Design</h3>
            <p>
                Once we have a thorough understanding of the task, we begin
                creating detailed information diagrams that outline how users
                will interact with your application. These details serve as the roadmap for
                wireframing, high level interactions, page elements, and hierarchy.
            </p>
        </div>
    );
}

export function SprintPlanning () {
    return (
        <div>
            <h3>Sprint Planning</h3>
            <p>
                With the wireframes and user stories complete, we start developing. With quick
                iterations we discover and fix problems early. We plan our sprints with the goal
                to have a collection of completed features at the end of each sprint.
            </p>
        </div>
    );
}

export function Development () {
    return (
        <div>
            <h3>Development</h3>
            <p>
                With tasks assigned, we begin to build your app. Each day, we hold a
                meetings to report what has been accomplished, what we intend to accomplish
                by the next meeting, and anything that stands in our way. This process keeps the
                entire everyone in the loop and any potential blockers on our radar.
            </p>
        </div>
    );
}

export function Launch () {
    return (
        <div>
            <h3>UAT + Launch</h3>
            <p>
                Before we ship your app, we go through the User Acceptance Testing process.
                We perform end-to-end testing in order to ensure the application meets your business
                needs. Once our Quality Assurance team gives us the green light, your
                app is ready for deployment!
            </p>
        </div>
    );
}
