// PACKAGES
import React from 'react';

/**
 * @name NotFound
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description NotFound view for mezmerikgroup.com.
 */
export default function NotFound () {
    return (
        <div className="container not-found">
            <h1>404 - Oh no's! We couldn't find that page</h1>
        </div>
    );
}
