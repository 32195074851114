// PACAKGES
import React, { Component } from 'react';
// COMPONENTS
import Button from './Button';
// UTILS
import Validator from '../utils/validator';

/**
 * @name Form
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Form for mezmerikgroup.com
 */
export default class Form extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    constructor (props) {
        super(props);

        const values = props.initialValues
            ? props.initialValues
            : React.Children.toArray(props.children).reduce((acc, input) => {
                acc[input.props.name] = '';
                return acc;
            }, {});

        this.state = {
            values,
            touched: {},
            errors: {},
            validation: React.Children.toArray(props.children).reduce((acc, input) => {
                acc[input.props.name] = input.props.validation;
                return acc;
            }, {}),
            showErrors: false,
        };
    }

    /**************************************************************
     * EVENT HANDLING
     *************************************************************/
    handleBlur = (event) => {
        const name = event.target.name;

        this.setState((state) => ({
            touched: {
                ...state.touched,
                [name]: true
            }
        }));
    }

    handleChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: value
            }
        }));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ showErrors: false, errors: {} });
        // Handle Validation Here
        const { isValid, errors } = this.handleValidation();

        return isValid
            ? this.props.onSubmit && this.props.onSubmit(this.state)
            : this.setState((state) => ({
                errors: {
                    ...state.errors,
                    ...errors
                },
                showErrors: true,
            }));
    }

    handleValidation = () => {
        const { validation, values } = this.state;
        const errors = Object.keys(values).reduce((acc, key) => {
            const value = values[key];

            validation[key].forEach((fn) => {
                if (typeof fn === 'string') {
                    const validator = new Validator(key, value);
                    const valid = validator[fn]().validate();

                    if (valid !== true) {
                        acc[key] = valid;
                    }
                }
            });

            return acc;
        }, {});

        return {
            errors,
            isValid: Object.keys(errors).length === 0
        };
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        return (
            <form className="form" onSubmit={this.handleSubmit}>
                {React.Children.map(this.props.children, (child) => {
                    if (!child.props.name) {
                        console.error('Each child of Form requires "name" prop with a unique value');
                    }
                    return React.cloneElement(child, {
                        onChange: this.handleChange,
                        onBlur: this.handleBlur,
                        value: this.state.values[child.props.name],
                        error: this.state.errors[child.props.name],
                        showErrors: this.state.showErrors,
                    });
                })}
                <Button category="secondary-inverted" type="submit">Contact</Button>
            </form>
        );
    }
}
