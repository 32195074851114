// PACKAGES
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * @name Logo
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Logo for mezmerikgroup.com
 */
export default function Logo () {
    return (
        <div className="logo">
            <Link className="logo__content" to="/">
                <span className="logo__text">Mezmerik<br /> Group</span>
            </Link>
        </div>
    );
}
