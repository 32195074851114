// PACKAGES
import React, { Component } from 'react';

/**
 * @name Header
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Header for mezmerikgroup.com
 */
export default class Header extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    header = React.createRef();

    componentDidMount () {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate () {
        const header = this.header.current;
        const navOpen = this.props.navOpen;

        return window.scrollY > 20 && !navOpen
            ? header.classList.add('header--active')
            : navOpen
                ? header.classList.remove('header--active')
                : null;
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**************************************************************
     * EVENT HANDLING
     *************************************************************/
    handleScroll = () => {
        const header = this.header.current;
        const navOpen = this.props.navOpen;

        return window.scrollY > 20 && !navOpen
            ? header.classList.add('header--active')
            : header.classList.remove('header--active');
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        return (
            <header ref={this.header} className="header">
                <div className="container header__container">{this.props.children}</div>
            </header>
        );
    }
}
