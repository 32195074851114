// PACKAGES
import React from 'react';
import { Link } from 'react-router-dom';
// COMPONENTS
import ContactForm from './ContactForm';

/**
 * @name Footer
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Footer for mezmerikgroup.com
 */
export default function Footer () {
    return (
        <footer className="footer" id="contact">
            <div className="footer__middle">
                <h1 className="footer__heading">Let's create something great</h1>
                <ContactForm />
            </div>
            <section className="container">
                <div className="footer__lower">
                    <a href="mailto:hello@mezmerikgroup.com"><span className="footer__text">hello@mezmerikgroup.com</span></a>
                    <Link to="/privacy-policy"><span className="footer__text">Privacy Policy</span></Link>
                    <a href="https://facebook.com/mezmerikgroup/"><span className="footer__text">Facebook</span></a>
                    <a href="https://twitter.com/mezmerikgroup"><span className="footer__text">Twitter</span></a>
                    <a href="https://linkedin.com/company/mezmerikgroup/"><span className="footer__text">Linkedin</span></a>
                </div>
            </section>
        </footer>
    );
}
