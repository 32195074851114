// PACKAGES
import React from 'react';

/**
 * @name ServiceGroup
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description ServiceGroup for mezmerikgroup.com
 */
export default function ServiceGroup ({ children }) {
    return (
        <div className="container service-group">
            {children}
        </div>
    );
}
