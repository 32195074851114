// PACAKGES
import React, { Component } from 'react';

/**
 * @name TextArea
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description TextArea for mezmerikgroup.com
 */
export default class TextInput extends Component {
    /**************************************************************
     * EVENT HANDLING
     *************************************************************/
    handleChange = (e) => {
        this.props.onChange && this.props.onChange(e);
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        const { name, value, error, showErrors, ...extra } = this.props;
        return (
            <React.Fragment>
                <input
                    {...extra}
                    className="text-input"
                    id={name}
                    name={name}
                    onChange={this.handleChange}
                    value={value}
                />
                {showErrors && error && (
                    <div className="text-input__validation">{error.message}</div>
                )}
            </React.Fragment>
        );
    }
}
