// PACKAGES
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// ROUTES
import routes from './routes';
// COMPONENTS
import Footer from '../components/Footer';
import Header from '../components/Header';
import Logo from '../components/Logo';
import Main from '../components/Main';
import Menu from '../components/Menu';
import Navigation from '../components/Navigation';
import NavigationMobile from '../components/NavigationMobile';
import ScrollIcon from '../components/ScrollIcon';
import ScrollRestoration from '../components/ScrollRestoration';
// STYLES
import '../styles/index.scss';

class App extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    state = {
        active: false,
    }

    /**************************************************************
     * EVENT HANDLING
     *************************************************************/
    handleNavigationToggle = () => this.setState({
        active: !this.state.active,
    })

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        const active = this.state.active;

        return (
            <Router>
                <ScrollRestoration>
                    <div className="app">
                        <NavigationMobile
                            active={active}
                            onClick={() => this.setState({ active: false })}
                        />
                        <Header navOpen={active}>
                            <Logo />
                            <div className="app__navigation">
                                <Navigation />
                            </div>
                            <div className="app__menu">
                                <Menu active={active} onClick={this.handleNavigationToggle} />
                            </div>
                        </Header>
                        <Main>
                            <Switch>
                                {Object.keys(routes).map((route) => {
                                    return (
                                        <Route
                                            component={routes[route].component}
                                            exact
                                            key={route}
                                            path={routes[route].path}
                                        />
                                    );
                                })}
                            </Switch>
                        </Main>
                        <Footer />
                    </div>
                    <ScrollIcon />
                </ScrollRestoration>
            </Router>
        );
    }
}

export default App;
