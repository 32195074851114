// PACKAGES
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * @name NavigationMobile
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description NavigationMobile for mezmerikgroup.com
 */
export default function NavigationMobile ({ active, onClick }) {
    const classList = active ? 'navigation-mobile navigation-mobile--active' : 'navigation-mobile';

    return (
        <nav className={classList}>
            <div className="navigation-mobile__menu">
                <Link
                    className="navigation-mobile__link"
                    onClick={onClick}
                    to="/#"
                >
                    <span className="navigation-mobile__span">Home</span>
                </Link>
                <Link
                    className="navigation-mobile__link"
                    onClick={onClick}
                    to="/#services"
                >
                    <span className="navigation-mobile__span">Service</span>
                </Link>
                <Link
                    className="navigation-mobile__link"
                    onClick={onClick}
                    to="/#process"
                >
                    <span className="navigation-mobile__span">Process</span>
                </Link>
                <Link
                    className="navigation-mobile__link"
                    onClick={onClick}
                    to="/#technology"
                >
                    <span className="navigation-mobile__span">Technology</span>
                </Link>
                <Link
                    className="navigation-mobile__link"
                    onClick={onClick}
                    to="/#contact"
                >
                    <span className="navigation-mobile__span">Contact</span>
                </Link>
            </div>
        </nav>
    );
}
