// PACKAGES
import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';
// COMPONENT
import Button from '../../components/Button';
import Section from '../../components/Section';
import Service from '../../components/Service';
import ServiceGroup from '../../components/ServiceGroup';
import Slider from '../../components/Slider';
import {
    Discovery,
    Design,
    SprintPlanning,
    Development,
    Launch,
} from '../../components/SlideContent';
// STATIC
import iconApi from '../../static/icon-api.png';
import iconMobile from '../../static/icon-mobile.png';
import iconWeb from '../../static/icon-web.png';
import iconcss3 from '../../static/icon-css3.png';
import iconhtml5 from '../../static/icon-html5.png';
import iconjavascript from '../../static/icon-javascript.png';
import iconnode from '../../static/icon-node.png';
import iconphp from '../../static/icon-php.png';
import iconpostgres from '../../static/icon-postgres.png';
import iconps from '../../static/icon-ps.png';
import iconreact from '../../static/icon-react.png';
import iconsass from '../../static/icon-sass.png';
import iconwordpress from '../../static/icon-wordpress.png';

const techIcons = {
    iconreact: {
        icon: iconreact,
        title: 'React/React Native',
    },
    iconjavascript: {
        icon: iconjavascript,
        title: 'JavaScript',
    },
    iconhtml5: {
        icon: iconhtml5,
        title: 'HTML 5',
    },
    iconsass: {
        icon: iconsass,
        title: 'Sass',
    },
    iconcss3: {
        icon: iconcss3,
        title: 'CSS 3',
    },
    iconnode: {
        icon: iconnode,
        title: 'Node JS',
    },
    iconpostgres: {
        icon: iconpostgres,
        title: 'PostgreSQL',
    },
    iconphp: {
        icon: iconphp,
        title: 'PHP',
    },
    iconps: {
        icon: iconps,
        title: 'PhotoShop',
    },
    iconwordpress: {
        icon: iconwordpress,
        title: 'Wordpress',
    },
};

/**
 * @name Home
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Home view for mezmerikgroup.com
 */
export default class Home extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    componentDidMount () {
        this.scrollToHash();
    }

    componentDidUpdate () {
        this.scrollToHash();
    }

    /**************************************************************
     * METHODS
     *************************************************************/
    scrollToHash = () => {
        const hash = this.props.location.hash;
        if (hash) {
            scrollToElement(hash, {
                offset: -77,
                duration: 750,
            });
        }
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        return (
            <div className="home" id="home">
                <div className="home__hero">
                    <a href="#contact">
                        <Button className="home__hero-button" category="secondary-inverted">
                            hello@mezmerikgroup.com
                        </Button>
                    </a>
                </div>
                <Section className="home__section" id="services">
                    <div className="container">
                        <h2>Services</h2>
                        <h3>Specializing in web and mobile application development</h3>
                        <p>
                            Mezmerik Group is a digital agency specializing in web and mobile app
                            design and development. We build digital products. Based out of Denver,
                            CO.
                        </p>
                    </div>
                    <ServiceGroup>
                        <Service title="Web Development" icon={iconWeb}>
                            Custom web solutions offer options for businesses who seek to
                            provide a flexible and consistent experience for users across
                            platforms.
                        </Service>
                        <Service title="Mobile Development" icon={iconMobile}>
                            We provide solutions with hybrid mobile applications for cross
                            platform support. We can help you select the best choice based
                            on your project's requirements.
                        </Service>
                        <Service title="API Development" icon={iconApi}>
                            We build scalible API solutions for growing businesses. We can
                            connect to an existing database or help with creating a new one.
                        </Service>
                    </ServiceGroup>
                </Section>
                <Section className="home__slider" black id="process">
                    <div className="container">
                        <h2>Process</h2>
                        <Slider>
                            <Discovery title="Discovery" />
                            <Design title="Design" />
                            <SprintPlanning title="Sprint Planning" />
                            <Development title="Development" />
                            <Launch title="UAT + Launch" />
                        </Slider>
                    </div>
                </Section>
                <Section id="technology">
                    <div className="container">
                        <h2>Technology Stack</h2>
                        <h3>Full-stack web and mobile app development</h3>
                        <p>
                            We can create what you need, from mobile applications to responsive
                            websites. We're well versed in the latest technologies and keep up to
                            date with modern advancements. Here are a few things we're currently
                            using:
                        </p>
                        <div className="home__icon-container">
                            {Object.keys(techIcons).map((key) => (
                                <div className="home__icon-wrapper" key={key}>
                                    <img className="home__icon" src={techIcons[key].icon} alt="" />
                                    <h5>{techIcons[key].title}</h5>
                                </div>
                            ))}
                        </div>
                    </div>
                </Section>
            </div>
        );
    }
}
