import React, { Component } from 'react'; // eslint-disable-line 
import { withRouter } from 'react-router-dom';

/**
 * @name ScrollRestoration
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description ScrollIcon inserts a scroll to top icon that is positions in the bottom right corner
 */
class ScrollRestoration extends Component {
    /**************************************************************
     * COMPONENT LIFECYCLE
     *************************************************************/
    componentDidUpdate (prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    /**************************************************************
     * RENDERING
     *************************************************************/
    render () {
        return this.props.children;
    }
}

/**************************************************************
 * EXPORT
 *************************************************************/
export default withRouter(ScrollRestoration);
