import Home from './views/Home';
import NotFound from './views/NotFound';
import PrivacyPolicy from './views/PrivacyPolicy';

export default {
    HOME: {
        component: Home,
        path: '/',
    },
    PRIVACY: {
        component: PrivacyPolicy,
        path: '/privacy-policy',
    },
    NOT_FOUND: {
        component: NotFound,
    },
};
