var Validator = function (field, value) {
    this.field = field;
    this.value = value;
    this.errors = null;

    return this;
};

Validator.prototype.required = function () {
    if (!this.value) {
        const key = this.field[0].toUpperCase() + this.field.substring(1);
        this.errors = { message: `${key} is required`, valid: false };
    }

    return this;
};

Validator.prototype.email = function () {
    const re = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    );

    if (!re.test(this.value)) {
        this.errors = {
            message: this.value ? `${this.value} is not a valid email address` : 'Not a valid email address',
            valid: false,
        };
    }

    return this;
};

Validator.prototype.validate = function () {
    return this.errors ? this.errors : true;
};

export default Validator;
