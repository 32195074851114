// PACKAGES
import React, { Component } from 'react';

/**
 * @name Slide
 * @type {ReactComponent}
 * @author Jake Feldman
 * @description Slide for mezmerikgroup.com
 */
export default class Slide extends Component {
    render () {
        const classList = this.props.visible ? 'slide slide--visible' : 'slide';

        return (
            <li className={classList}>
                {this.props.children}
            </li>
        );
    }
}
